/* necessary for tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* color variables - also set in tailwind.config.js */
:root {
  --white: #ffffff;
  --black: #000000;
  --main: linear-gradient(133deg, rgba(27,227,227,1) 48%, rgba(0,212,255,1) 100%);
  --accent1: rgb(27,227,227);
  --accent2: rgb(13,219,241);
  --accent3: rgb(0, 212, 255);
  --accent4: rgb(220, 222, 222);
}

/* ===== START GLOBAL CSS ====== */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

a, button {
  cursor: pointer;
}

ul {
  list-style-type: none;
}

body.active-modal {
  overflow-y: hidden;
  overflow-x: hidden;
}
/* ===== END GLOBAL CSS ====== */